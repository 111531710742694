import React, { useState, useEffect } from "react";
import { collection, addDoc, setDoc, doc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const Dashboard = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [workType, setWorkType] = useState("現場");
  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:45");

  useEffect(() => {
    const fetchRecords = async () => {
      const recordsRef = collection(db, "time_cards", user.uid, "records");
      const snapshot = await getDocs(recordsRef);
      const recordsData = snapshot.docs.map(doc => doc.data());
      setRecords(recordsData);
    };
    fetchRecords();
  }, [user]);

  const handleClockIn = async () => {
    const date = new Date().toISOString().split("T")[0];
    await setDoc(doc(db, "time_cards", user.uid, "records", date), {
      date,
      workType,
      clockIn,
      clockOut: null
    }, { merge: true });
    alert("Clock in recorded");
  };

  const handleClockOut = async () => {
    const date = new Date().toISOString().split("T")[0];
    await updateDoc(doc(db, "time_cards", user.uid, "records", date), {
      clockOut
    });
    alert("Clock out recorded");
  };

  return (
    <div>
      <h1>Welcome, {user.email}</h1>
      <div>
        <label>Work Type:</label>
        <select value={workType} onChange={(e) => setWorkType(e.target.value)}>
          <option value="現場">現場</option>
          <option value="自社">自社</option>
          <option value="テレワーク">テレワーク</option>
        </select>
      </div>
      <div>
        <label>Clock In:</label>
        <input type="time" value={clockIn} onChange={(e) => setClockIn(e.target.value)} />
      </div>
      <div>
        <label>Clock Out:</label>
        <input type="time" value={clockOut} onChange={(e) => setClockOut(e.target.value)} />
      </div>
      <button onClick={handleClockIn}>Clock In</button>
      <button onClick={handleClockOut}>Clock Out</button>
      <h2>Records</h2>
      <ul>
        {records.map((record, index) => (
          <li key={index}>{record.date}: {record.workType} {record.clockIn} - {record.clockOut}</li>
        ))}
      </ul>
    </div>
  );
};

export default Dashboard;
